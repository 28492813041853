export const PowerBiEnumstg = {
  salesToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8c81de09-4fc8-4d21-bb77-4728702afb93',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8c81de09-4fc8-4d21-bb77-4728702afb93&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newsalesToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'fa102676-dd8b-4119-b4af-2c9c1c4c0333',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fa102676-dd8b-4119-b4af-2c9c1c4c0333&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  teamleadToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '5fab3b3d-bf50-482e-9834-864add46b42c',
    url: 'https://app.powerbi.com/reportEmbed?reportId=5fab3b3d-bf50-482e-9834-864add46b42c&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  agentToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '85cc9b06-5705-45ec-9fad-5f339e9eaec2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=85cc9b06-5705-45ec-9fad-5f339e9eaec2&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  leaseAgentToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'e6488002-c0ec-4268-ad38-2a60c22ecce8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=e6488002-c0ec-4268-ad38-2a60c22ecce8&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  leasingToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'eaa66fa2-3fc7-4fb2-9c1b-2775f0566b75',
    url: 'https://app.powerbi.com/reportEmbed?reportId=eaa66fa2-3fc7-4fb2-9c1b-2775f0566b75&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  NewleasingToken: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8c907e78-4424-465f-a5a3-37b3c48f8dd3',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8c907e78-4424-465f-a5a3-37b3c48f8dd3&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  callCenter: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '0667ec04-e216-4397-a91a-7e910126f0c2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=0667ec04-e216-4397-a91a-7e910126f0c2&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newcallCenter: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'ab42b918-80fa-4654-bf05-0bd4b31146d7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=ab42b918-80fa-4654-bf05-0bd4b31146d7&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  marketing: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8bfa5689-bc70-4027-a57d-98100119ab29',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8bfa5689-bc70-4027-a57d-98100119ab29&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newmarketing: {
    groupid: "fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2",
    reportid: "a873d207-6ede-4632-8438-9e94ebea52b1",
    url: "https://app.powerbi.com/reportEmbed?reportId=a873d207-6ede-4632-8438-9e94ebea52b1&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19",
  },
  psidaily: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '9fc2cabd-c713-4ff5-a26f-e114d7b0e915',
    url: 'https://app.powerbi.com/reportEmbed?reportId=9fc2cabd-c713-4ff5-a26f-e114d7b0e915&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  Newpsidaily: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'ee6b6a95-32d5-4e56-80f0-be953b497bdf',
    url: 'https://app.powerbi.com/reportEmbed?reportId=ee6b6a95-32d5-4e56-80f0-be953b497bdf&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  saleslistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '34e7829b-ed37-4501-b6c1-4bccdcb812c6',
    url: 'https://app.powerbi.com/reportEmbed?reportId=34e7829b-ed37-4501-b6c1-4bccdcb812c6&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  newsaleslistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '6ccf6b79-2e10-4ae8-a313-934dbedee89d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=6ccf6b79-2e10-4ae8-a313-934dbedee89d&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  SalesListingUnitsDate: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'fe7aa4dd-af93-4882-8c50-e8da3e4f53e7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fe7aa4dd-af93-4882-8c50-e8da3e4f53e7&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  LeaseListingUnitsDate: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'a14b0ee7-54ce-49a0-8af0-ce5f37dcf07a',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a14b0ee7-54ce-49a0-8af0-ce5f37dcf07a&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  leaselistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f923e598-53d8-4195-ac75-25e6c90ce3ed',
    url: 'https://app.powerbi.com/reportEmbed?reportId=ff378a3e-c53d-4211-b77a-9d3630ddb4d7&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsImFuZ3VsYXJPbmx5UmVwb3J0RW1iZWQiOnRydWUsImNlcnRpZmllZFRlbGVtZXRyeUVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwic2tpcFpvbmVQYXRjaCI6dHJ1ZX19',
  },
  Newleaselistingmanager: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'd2c47cef-57ad-4da6-8df4-d9e12b5b4045',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d2c47cef-57ad-4da6-8df4-d9e12b5b4045&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  accounts: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '635ab45b-1330-4830-82ac-017453ae38fb',
    url: 'https://app.powerbi.com/reportEmbed?reportId=635ab45b-1330-4830-82ac-017453ae38fb&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  intentionalsales: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '91634f04-b822-4b42-8449-a72ee9be273e',
    url: 'https://app.powerbi.com/reportEmbed?reportId=91634f04-b822-4b42-8449-a72ee9be273e&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  dubai_lease_listing_admin: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '6f4bc7fa-d9c7-42dd-b3b3-afeb0021ce9d',
    url: 'https://app.powerbi.com/reportEmbed?reportId=6f4bc7fa-d9c7-42dd-b3b3-afeb0021ce9d&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  dubai_lease_listing_agent: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'c6dfbed6-2122-4590-ae8a-a272fd4b42e5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=c6dfbed6-2122-4590-ae8a-a272fd4b42e5&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  dubai_sales_listing_admin: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '98da1945-916c-495e-9f4a-93b751c67788',
    url: 'https://app.powerbi.com/reportEmbed?reportId=98da1945-916c-495e-9f4a-93b751c67788&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  dubai_sales_listing_agent: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'a4ef57e8-568f-4eea-ad5e-97261b8a05b5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=a4ef57e8-568f-4eea-ad5e-97261b8a05b5&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_1: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'fd1f176f-0d01-4a24-91da-7076cb0d5846',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fd1f176f-0d01-4a24-91da-7076cb0d5846&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_2: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'b89a7df7-2029-4757-b8d1-3244fb75ab8c',
    url: 'https://app.powerbi.com/reportEmbed?reportId=b89a7df7-2029-4757-b8d1-3244fb75ab8c&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_3: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8d99ee48-042c-4853-977b-bbc587770ac2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8d99ee48-042c-4853-977b-bbc587770ac2&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_4: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f00d74ab-163f-450c-99df-46eb77473bd8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f00d74ab-163f-450c-99df-46eb77473bd8&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_5: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f00618e5-7112-4a41-a2d4-709a7c1a3ed3',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f00618e5-7112-4a41-a2d4-709a7c1a3ed3&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_6: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '4d2414a3-456e-4a2a-b86d-798225f5eaa5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=4d2414a3-456e-4a2a-b86d-798225f5eaa5&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_7: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'fdfc7e38-bc6d-437e-83d0-c777e0192e98',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fdfc7e38-bc6d-437e-83d0-c777e0192e98&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_8: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '3cb4ef98-043e-49bb-8c7f-9dfde1d493af',
    url: 'https://app.powerbi.com/reportEmbed?reportId=3cb4ef98-043e-49bb-8c7f-9dfde1d493af&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_9: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '85ad52b7-bd56-4f81-9d8b-5e35d2af7fa7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=85ad52b7-bd56-4f81-9d8b-5e35d2af7fa7&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  tab_10: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '3277b670-1f6e-4d21-b60d-5beb2fabb925',
    url: 'https://app.powerbi.com/reportEmbed?reportId=3277b670-1f6e-4d21-b60d-5beb2fabb925&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  MarketingClientsSegmentation: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '2f6cdd1f-eb84-42ae-aae9-6356064d19fb',
    url: 'https://app.powerbi.com/reportEmbed?reportId=2f6cdd1f-eb84-42ae-aae9-6356064d19fb&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  Branches: {
    groupid: 'aa196a41-662c-47d5-a998-e40c7dad64c3',
    reportid: 'eb28fd29-1be4-4e30-bdbf-7c79fb91d7d5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=eb28fd29-1be4-4e30-bdbf-7c79fb91d7d5&groupId=aa196a41-662c-47d5-a998-e40c7dad64c3&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  Salesdirector: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '6ca9e9db-9a0b-40f1-9262-82380d5a387b',
    url: 'https://app.powerbi.com/reportEmbed?reportId=6ca9e9db-9a0b-40f1-9262-82380d5a387b&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  Leasedirector: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'bb5e7954-b3cc-4a5f-aeb7-1d4930a2dc73',
    url: 'https://app.powerbi.com/reportEmbed?reportId=bb5e7954-b3cc-4a5f-aeb7-1d4930a2dc73&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJza2lwUXVlcnlEYXRhU2FhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhUGFhU0VtYmVkIjp0cnVlLCJza2lwUXVlcnlEYXRhRXhwb3J0VG8iOnRydWV9fQ%3d%3d',
  },
  Leasedetails: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8b2b6a09-0a36-4833-a868-103abe9363ac',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8b2b6a09-0a36-4833-a868-103abe9363ac&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  LeadOwner: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '86731959-6655-417f-a388-61aac503b2c6',
    url: 'https://app.powerbi.com/reportEmbed?reportId=86731959-6655-417f-a388-61aac503b2c6&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  UserLogin: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '4d52f37e-7ab2-4a84-a444-b6fd490a7200',
    url: 'https://app.powerbi.com/reportEmbed?reportId=38951a74-53ae-4742-8707-478880e41cde&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  ActivatedLeadid: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f25c6a25-414b-4952-9460-8c31c54c3ae8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f25c6a25-414b-4952-9460-8c31c54c3ae8&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  CustomersRisk: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '601f080f-b325-4970-b437-ca2a2b88e4e5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=601f080f-b325-4970-b437-ca2a2b88e4e5&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  PropertyFinder: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'edcc1002-e063-4037-aa64-81e9efb0e8ae',
    url: 'https://app.powerbi.com/reportEmbed?reportId=edcc1002-e063-4037-aa64-81e9efb0e8ae&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  Bayut: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'd13ba434-fb3f-4cd8-8295-cfccca5164c6',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d13ba434-fb3f-4cd8-8295-cfccca5164c6&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  SalesAgentplus: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'cee9c2a0-77e1-42a1-bc8f-c8f5897326e3',
    url: 'https://app.powerbi.com/reportEmbed?reportId=cee9c2a0-77e1-42a1-bc8f-c8f5897326e3&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlfX0%3d',
  },
  SalesTeamLeadplus: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '1666ab4e-f011-4b22-b873-fe012cd9bd08',
    url: 'https://app.powerbi.com/reportEmbed?reportId=1666ab4e-f011-4b22-b873-fe012cd9bd08&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  SalesDirectorplus: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'd10df5cc-7dca-4bef-a8f9-459243fe20c4',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d10df5cc-7dca-4bef-a8f9-459243fe20c4&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  SalesDirectorplus_Date: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '40488698-e323-4b0b-af3d-d293ff299bde',
    url: 'https://app.powerbi.com/reportEmbed?reportId=40488698-e323-4b0b-af3d-d293ff299bde&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeaseDirectorplus: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '64f25988-b205-46ce-874a-5108438425a2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=64f25988-b205-46ce-874a-5108438425a2&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  LeaseDirectorplus_Date: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '263a8fc5-56f0-4c22-961a-dba6a264f563',
    url: 'https://app.powerbi.com/reportEmbed?reportId=263a8fc5-56f0-4c22-961a-dba6a264f563&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeaseAgentplus: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '4c8bddeb-d44a-4a1e-ac9c-3aa4cddf3e52',
    url: 'https://app.powerbi.com/reportEmbed?reportId=4c8bddeb-d44a-4a1e-ac9c-3aa4cddf3e52&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  LeaseTeamLeadplus: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'e24be139-8c12-4335-8d10-ad71f3eb5bf1',
    url: 'https://app.powerbi.com/reportEmbed?reportId=e24be139-8c12-4335-8d10-ad71f3eb5bf1&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsibW9kZXJuRW1iZWQiOnRydWUsInVzYWdlTWV0cmljc1ZOZXh0Ijp0cnVlLCJkaXNhYmxlQW5ndWxhckpTQm9vdHN0cmFwUmRsRW1iZWQiOnRydWV9fQ%3d%3d',
  },
  SalesTeamLeadDate: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'e5b1b554-af58-4422-b2f8-c2c007df418e',
    url: 'https://app.powerbi.com/reportEmbed?reportId=e5b1b554-af58-4422-b2f8-c2c007df418e&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeaseTeamLeadDate: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'b869e8d9-a5ed-480e-b28e-05ce029e54af',
    url: 'https://app.powerbi.com/reportEmbed?reportId=b869e8d9-a5ed-480e-b28e-05ce029e54af&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  RotationScheme: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '99ceeeea-c547-4fae-9c51-cf8645cb0d2e',
    url: 'https://app.powerbi.com/reportEmbed?reportId=99ceeeea-c547-4fae-9c51-cf8645cb0d2e&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  CampaignCost: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f239c7e4-64c2-45b6-8eac-9233685f7346',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f239c7e4-64c2-45b6-8eac-9233685f7346&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  SegmentationLeadBranch: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '20733e8d-8d39-49db-8cc4-a6431c8b703a',
    url: 'https://app.powerbi.com/reportEmbed?reportId=20733e8d-8d39-49db-8cc4-a6431c8b703a&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  SegmentationContactBranch: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f854875b-d98d-405b-9957-d0c5398c5acb',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f854875b-d98d-405b-9957-d0c5398c5acb&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  SegmentationOwnerBranch: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '7eb516fd-e6bd-4823-bed7-128b6b72fbb0',
    url: 'https://app.powerbi.com/reportEmbed?reportId=7eb516fd-e6bd-4823-bed7-128b6b72fbb0&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  callCenterDate: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'f7d3bdef-4275-4f9f-959a-fb13f3a7959f',
    url: 'https://app.powerbi.com/reportEmbed?reportId=f7d3bdef-4275-4f9f-959a-fb13f3a7959f&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  LeadAutomation: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'e027c673-e74f-4426-94b5-2df3c8a2a1f8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=e027c673-e74f-4426-94b5-2df3c8a2a1f8&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  UnqualifiedLeads: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '7ab832db-72cf-41f9-b1b0-6536317cb0dc',
    url: 'https://app.powerbi.com/reportEmbed?reportId=7ab832db-72cf-41f9-b1b0-6536317cb0dc&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  InternationalSalesYTDMTD: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'eae4e34a-b1e9-4ee3-8ad9-d73a0ac624b2',
    url: 'https://app.powerbi.com/reportEmbed?reportId=eae4e34a-b1e9-4ee3-8ad9-d73a0ac624b2&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  UsersDetails: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '6231b977-5884-4fd0-82d3-816166f68d18',
    url: 'https://app.powerbi.com/reportEmbed?reportId=6231b977-5884-4fd0-82d3-816166f68d18&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  PortalsCampaignChart: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '5f5a841b-7ba7-49d9-85be-a95431abb098',
    url: 'https://app.powerbi.com/reportEmbed?reportId=5f5a841b-7ba7-49d9-85be-a95431abb098&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  ListingAllUnits: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '099f7c9a-606f-4372-b63b-7af3ce5f4fc8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=099f7c9a-606f-4372-b63b-7af3ce5f4fc8&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19',
  },
  AML: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'ce9e7f1e-e63e-4c77-8697-576b41a12cf7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=ce9e7f1e-e63e-4c77-8697-576b41a12cf7&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  Projecttask: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'b4a3a279-ddde-4af3-ab42-9590085544df',
    url: 'https://app.powerbi.com/reportEmbed?reportId=b4a3a279-ddde-4af3-ab42-9590085544df&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  CampaignRequest: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '8e49d94e-43a8-4ae7-bacd-312b63fb2574',
    url: 'https://app.powerbi.com/reportEmbed?reportId=8e49d94e-43a8-4ae7-bacd-312b63fb2574&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeadPool: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'd83da802-65f4-4438-a26b-a2da7926e5fa',
    url: 'https://app.powerbi.com/reportEmbed?reportId=d83da802-65f4-4438-a26b-a2da7926e5fa&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  LeadFromPool: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '6acc8158-7e20-48e6-ad6a-b98f1c112ab5',
    url: 'https://app.powerbi.com/reportEmbed?reportId=6acc8158-7e20-48e6-ad6a-b98f1c112ab5&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  AdminLeadFromPool: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '65b430cc-4cf7-4a26-8dab-7b1f963352e3',
    url: 'https://app.powerbi.com/reportEmbed?reportId=65b430cc-4cf7-4a26-8dab-7b1f963352e3&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  zeromatching: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '29305cbc-f1c6-4c2c-8c04-f4d9d4a82c6f',
    url: 'https://app.powerbi.com/reportEmbed?reportId=29305cbc-f1c6-4c2c-8c04-f4d9d4a82c6f&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  developerProperty: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '92e1f167-17e8-4c89-8ebd-a5715c1c5d76',
    url: 'https://app.powerbi.com/reportEmbed?reportId=92e1f167-17e8-4c89-8ebd-a5715c1c5d76&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  activity: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'fadab20c-a0d9-4496-a933-4043312901a7',
    url: 'https://app.powerbi.com/reportEmbed?reportId=fadab20c-a0d9-4496-a933-4043312901a7&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  Ai_Call: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '40cdc41c-fe28-43e8-a0ef-1d6402d98b14',
    url: 'https://app.powerbi.com/reportEmbed?reportId=40cdc41c-fe28-43e8-a0ef-1d6402d98b14&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  ProposalTracking: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'bb93e95f-3718-4d96-b24a-263bfe6e1da8',
    url: 'https://app.powerbi.com/reportEmbed?reportId=bb93e95f-3718-4d96-b24a-263bfe6e1da8&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  Inventory: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: 'c64cf6ed-407d-405d-949a-2576770936e6',
    url: 'https://app.powerbi.com/reportEmbed?reportId=c64cf6ed-407d-405d-949a-2576770936e6&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  Ai_Call_Team: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '7a216b2c-a0f4-49e0-ac07-05290bde06a1',
    url: 'https://app.powerbi.com/reportEmbed?reportId=7a216b2c-a0f4-49e0-ac07-05290bde06a1&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
  Ai_Call_Agent: {
    groupid: 'fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2',
    reportid: '84f4ba00-7533-4199-a04f-bc84ba98cbdb',
    url: 'https://app.powerbi.com/reportEmbed?reportId=84f4ba00-7533-4199-a04f-bc84ba98cbdb&groupId=fb8582d0-052b-4a26-ba8b-a2f1d0bb79e2&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVdFU1QtRVVST1BFLUUtUFJJTUFSWS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWV9fQ%3d%3d',
  },
};

